import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ResumoContainer } from './Resumo.styles.jsx';
import PencilIcon from '../../Components/Images/Resumo/pencil-icon.svg';
import { useAuth } from '../../AuthContext';
import CryptoJS from 'crypto-js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default function Resumo() {
    const navigate = useNavigate();
    const [attendantName, setAttendantName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [jsonOutput, setJsonOutput] = useState({});
    const [userPharmacyId, setUserPharmacyId] = useState('');

    const { currentStep } = useAuth();
    useEffect(() => {
        if (currentStep < 2) {
            navigate('/protocolo');
        }
    }, [currentStep, navigate]);

    useEffect(() => {
        const storedJsonOutput = localStorage.getItem('jsonOutput');
        if (storedJsonOutput) {
            setJsonOutput(JSON.parse(storedJsonOutput));
        }

        const encryptedUserPharmacyId = localStorage.getItem('userPharmacyId');
        if (encryptedUserPharmacyId) {
            const decryptedUserPharmacyId = CryptoJS.AES.decrypt(encryptedUserPharmacyId, SECRET_KEY).toString(CryptoJS.enc.Utf8);
            setUserPharmacyId(decryptedUserPharmacyId);
        }
    }, []);

    const handleInputChange = (event) => {
        setAttendantName(event.target.value);
    };

    const handleFinalizar = async () => {
        if (attendantName.trim() !== '') {
            const validItems = jsonOutput.itens_troca?.filter(item => item.numero_serie && item.lote && item.validade) || [];

            const updatedJsonOutput = {
                ...jsonOutput,
                nome_operador: attendantName,
                cod_farmacia: userPharmacyId, // Adicionando o userPharmacyId ao JSON
                itens_troca: validItems
            };

            //console.log("JSON enviado:", JSON.stringify(updatedJsonOutput, null, 2));

            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/exchange/`,
                    updatedJsonOutput,
                    { withCredentials: true }
                );

                if (response.status === 201) {
                    setIsModalOpen(true);
                    localStorage.removeItem('jsonOutput');
                }
            } catch (error) {
                if (error.response) {
                    const { status, data } = error.response;
                    if (status === 400 || status === 404 || status === 500) {
                        setErrorMessage(data.erro);
                    } else {
                        setErrorMessage('Erro desconhecido ao tentar salvar a troca');
                    }
                } else {
                    setErrorMessage('Erro na conexão com o servidor');
                }
            }
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/informacoes');
    };

    const validItems = jsonOutput.itens_troca?.filter(item => item.numero_serie && item.lote && item.validade) || [];
    const remainingItemsCount = jsonOutput.itens_troca?.length - validItems.length || 0;

    return (
        <>
            <ResumoContainer>
                <h2>Resumen del intercambio</h2>
                <br />
                <div className='boxes-div'>
                    <div className='box-white'>
                        <div className='box-white-top'>
                            <div>
                                <strong>Ítems entregados: </strong>
                                <span>{validItems.length}</span>
                            </div>
                            <div onClick={() => navigate('/protocolo')} className='icon-div'>
                                <img src={PencilIcon} alt="pencil-icon" />
                                <a>Editar</a>
                            </div>
                        </div>
                        {validItems.map((item, index) => (
                            <div className='box-white-bottom' key={index}>
                                <div>
                                    <strong>{item.nome_item}</strong>
                                </div>
                                <div>
                                    <span>N° de Serie: </span>
                                    <span>{item.numero_serie}</span>
                                </div>
                                <div>
                                    <span>Lote: </span>
                                    <span>{item.lote}</span>
                                </div>
                                <div>
                                    <span>Vencimiento: </span>
                                    <span>{item.validade}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='box-gray'>
                        <div>
                            <strong>Ítems restantes en el número de caso: </strong>
                            <span>{remainingItemsCount}</span>
                        </div>
                        <div className='device-items'>
                            {jsonOutput.itens_troca?.filter(item => !item.numero_serie && !item.lote && !item.validade).map((item, index) => (
                                <div key={index}>
                                    <span>{item.nome_item}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <br />
                <div className='nome-atendente-div'>
                    <strong>Nombre completo del atendente</strong>
                    <div>
                        <input className='form-control' type="text" value={attendantName} onChange={handleInputChange} />
                        <button className={`button ${attendantName.trim() === '' ? 'disabled' : ''}`} onClick={handleFinalizar} disabled={attendantName.trim() === ''}>Finalizar</button>
                    </div>
                </div>
                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title"><span className="glyphicon glyphicon-ok-circle" aria-hidden="true"></span> Completado</h4>
                            </div>
                            <div className="modal-body">
                                <p>El proceso de intercambio ha sido completado correctamente.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary ok_btn" onClick={handleModalClose}>Entendido</button>
                            </div>
                        </div>
                    </div>
                )}
            </ResumoContainer>
        </>
    );
}
